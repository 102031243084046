exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-algolia-template-js": () => import("./../../../src/templates/algolia-template.js" /* webpackChunkName: "component---src-templates-algolia-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-blogs-template-js": () => import("./../../../src/templates/blogs-template.js" /* webpackChunkName: "component---src-templates-blogs-template-js" */),
  "component---src-templates-destination-template-js": () => import("./../../../src/templates/destination-template.js" /* webpackChunkName: "component---src-templates-destination-template-js" */),
  "component---src-templates-landing-page-template-js": () => import("./../../../src/templates/landing-page-template.js" /* webpackChunkName: "component---src-templates-landing-page-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-responsible-travel-template-js": () => import("./../../../src/templates/responsible-travel-template.js" /* webpackChunkName: "component---src-templates-responsible-travel-template-js" */)
}

