import React, { createContext, useContext, useEffect, useState } from 'react'
import { globalHistory } from '@reach/router'

const HistoryContext = createContext([])

const HistoryProvider = ({ children }) => {
  const [history, setHistory] = useState([window.location.href])

  useEffect(() => {
    const unsubscribe = globalHistory.listen(({ location }) => {
      setHistory((h) => h.concat(location.href))
    })
    return () => unsubscribe()
  }, [])

  return <HistoryContext.Provider value={history}>{children}</HistoryContext.Provider>
}

const useHistory = () => {
  const context = useContext(HistoryContext)
  if (!context) {
    throw new Error('useHistory must be used within a HistoryProvider')
  }
  return context
}

export { HistoryProvider, HistoryContext, useHistory }
